$(document).ready(
    function () {
        let p = $("body").attr("p");
        let a = $("body").attr("a");
        if (p === 'event' && a == 'show') {
            let eventId = $("body").attr("event_id");
            const DateTime = easepick.DateTime;
            let getAvailableSubEventsAjaxLock = false;
            if (!getAvailableSubEventsAjaxLock && eventId.length > 0 && !isNaN(eventId)) {
                getAvailableSubEventsAjaxLock = true;
                $.ajax({
                    type: 'POST',
                    url: "/ajax/organizer_event/get_available_subevents",
                    data: {
                        event_id: eventId
                    }
                })
                    .done(function (data) {
                        let allowedDates = [];
                        let minDate = new Date();
                        let maxDate = null;
                        if (data.availableDates !== undefined && data.availableDates !== null) {
                            allowedDates = data.availableDates;
                        }
                        if (data.minDate !== undefined && data.minDate !== null) {
                            minDate = new DateTime(data.minDate, 'YYYY-MM-DD');
                        }
                        if (data.maxDate !== undefined && data.maxDate !== null) {
                            maxDate = new DateTime(data.maxDate, 'YYYY-MM-DD');
                        }
                        getDatePeriodPicker(allowedDates, minDate, maxDate);
                    })
                    .fail(function (data) {
                        if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                            document.location.href = data.responseJSON.redirect_url;
                        }
                        if (data.responseJSON.error_message !== undefined && data.responseJSON.error_message !== null) {
                            popupAlert(3000, 'danger', data.responseJSON.error_message);
                        }
                        getAvailableSubEventsAjaxLock = false;

                    })
                    .always(function () {
                        getAvailableSubEventsAjaxLock = false;
                    });
            }

            $(document).on('click', '#accomodation-datepicker-calendar-icon', function () {
                $('#accommodation-datepicker').click();
            });

            function getDatePeriodPicker(allowedDates, minDate, maxDate) {
                const allAllowedDates = allowedDates.map(d => {
                    if (d instanceof Array) {
                        const start = new DateTime(d[0], 'YYYY-MM-DD');
                        const end = new DateTime(d[1], 'YYYY-MM-DD');

                        return [start, end];
                    }
                    return new DateTime(d, 'YYYY-MM-DD');
                });

                const picker = new easepick.create({
                    element: document.getElementById('accommodation-datepicker'),
                    css: [
                        '/node_modules/@easepick/bundle/dist/index.css',
                        '/src/css/easepick.css?2'
                    ],
                    setup(picker) {
                        picker.on('select', (e) => {
                            if (!selectDateRange(e.detail.start.format('YYYY-MM-DD'), e.detail.end.format('YYYY-MM-DD'))) {
                                setTimeout(function () {
                                    picker.show();
                                }, 500);
                            }
                        });
                    },
                    grid: 2,
                    calendars: 2,
                    zIndex: 10,
                    lang: 'no-NO',
                    autoApply: false,
                    locale: {
                        cancel: 'Avbryt',
                        apply: 'Velg'
                    },
                    format: 'DD-MM-YYYY',
                    plugins: ['RangePlugin', 'LockPlugin', 'AmpPlugin'],
                    RangePlugin: {
                        tooltipNumber(num) {
                            return num - 1;
                        },
                        locale: {
                            one: 'natt',
                            other: 'netter',
                        },
                    },
                    LockPlugin: {
                        minDate: minDate,
                        minDays: 1,
                        inseparable: true,
                        maxDate: maxDate,
                        filter(date, picked) {
                            if (picked.length === 1) {
                                const incl = date.isBefore(picked[0]) ? '[)' : '(]';
                                return !picked[0].isSame(date, 'day') && !date.inArray(allAllowedDates, incl);
                            }
                            return !date.inArray(allAllowedDates, '[)');
                        },
                    },
                    AmpPlugin: {
                        darkMode: true,
                        resetButton: true
                    }
                });
            }

            function selectDateRange(start, end) {
                let body = $("body");
                let ok = false;
                $.ajax({
                    type: 'POST',
                    url: "/ajax/organizer_event/check_subevents",
                    async: false,
                    data: {
                        event_id: body.attr("event_id"),
                        organizer_id: body.attr("organizer_id"),
                        start: start,
                        end: end
                    }
                })
                    .done(function (data) {
                        if (data.url !== undefined && data.url !== null) {
                            document.location.href = data.url;
                        } else {
                            ok = false;
                        }
                    })
                    .fail(function (data) {
                        ok = false;
                        if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                            document.location.href = data.responseJSON.redirect_url;
                        }
                        if (data.responseJSON.error_message !== undefined && data.responseJSON.error_message !== null) {
                            popupAlert(3000, 'danger', data.responseJSON.error_message);
                        }
                        getAvailableSubEventsAjaxLock = false;

                    })
                    .always(function () {
                        getAvailableSubEventsAjaxLock = false;
                    });
                return ok;
            }

            $(document).on('click', '.event-accommodation-list .row.event-ticket-category-list', function () {
                $('input.ticket-to-order', $(this)).val(1).change();
                $('#div_order_buttons #order-next-button').click();
            });
        }
    }
);